import React, { forwardRef } from "react";
import LongRightArrow from "../Assets/LongRightArrow.svg";
import BoyVr from "../Assets/BoyVr.svg";

function About({}, ref) {
  
  return (
    <div id="" ref={ref}>
      <div className="flex flex-wrap justify-center sm:justify-between mt-20">
        <div className="">
          <p className="text-white font-bold text-4xl w-[10%]">INTRODUCTION</p>

          <div className="flex items-center gap-14 ">
            <p className="text-white font-light text-4xl">TO TECHFLIX</p>
            <img className="sm:block hidden" src={LongRightArrow} alt="" />
          </div>
        </div>
        <p className="text-white sm:block hidden text-base w-1/2">
          Welcome to Techlix, your gateway to the world of augmented and virtual
          reality. We're passionate about pushing the boundaries of technology
          and delivering immersive experiences that will leave you awestruck.
          Whether you're looking to explore new frontiers or unlock new
          opportunities, Techlix has the solutions you need to succeed in
          today's fast-paced, technology-driven world.
        </p>
      </div>
      <div className="flex flex-wrap justify-between items-center mt-20">
        <img className="sm:w-35% w-full" src={BoyVr} alt="x" />
        <div className="text-white sm:w-50% sm:mx-auto w-full sm:text-left text-center">
          <p className="font-bold text-4xl sm:block hidden">ABOUT</p>
          <p className="font-light text-4xl sm:block hidden">TECH FLIX</p>
          <div className=" mt-10">
            <p>
              Tech Flix is an innovative AR VR company that was founded in 2021.
              Our mission is to create software, content, and simulations that
              push the boundaries of what's possible in the world of augmented
              and virtual reality. We're dedicated to delivering the highest
              quality solutions to our clients, using the latest technologies
              and best practices to ensure that our applications are reliable,
              efficient, and user-friendly.
            </p>
            <br />
            <p className="sm:block hidden">
              At Tech Flix, we're committed to staying at the forefront of AR VR
              technology and pushing the limits of what's possible. Our team of
              expert developers, designers, and engineers works closely with our
              clients to understand their unique needs and develop customized
              solutions that meet their specific requirements.
            </p>
          </div>
          <button className=" text-center text-black sm:mx-auto text-xs px-6 py-3 mt-6 rounded-10 bg-gradient-to-br from-[#8176AF] to-[#C0B7E8]">
            LET’S GET IN TOUCH
          </button>
        </div>
      </div>
    </div>
  );
}

export default forwardRef(About);
