import React, { forwardRef } from "react";
import TechflixLogo from "../Assets/TechflixLogo.svg";
import VerticalPartition from "../Assets/VerticalPartition.svg";
import facebook from "../Assets/facebook.svg";
import twitter from "../Assets/twitter.svg";
import linkedin from "../Assets/linkedin.svg";
import youtube from "../Assets/youtube.svg";
import instagram from "../Assets/instagram.svg";
import pinterest from "../Assets/pinterest.svg";
import Endline from "../Assets/Endline.svg";

function Footer({}, ref) {
  return (
    <div ref={ref}>
      <div className="flex flex-wrap sm:flex-nowrap justify-center sm:justify-start">
        <img className="w-60% sm:w-[14%]" src={TechflixLogo} alt=''/>
        <img className="mx-20 sm:block hidden" src={VerticalPartition} alt=''/>

        <ul className="space-y-5 font-bold text-base text-white sm:block hidden  ">
          <li>ABOUT</li>
          <li>SERVICES</li>
          <li>TECHNOLOGIES</li>
          <li>HOW TO</li>
          <li>JOIN TECHFLIX</li>
        </ul>
        <img className="mx-20 sm:block hidden" src={VerticalPartition} alt=''/>

        <ul className="space-y-5 h-[32vh] font-bold text-base text-white sm:block hidden ">
          <li>F.A.Q</li>
          <li>SITEMAP</li>
          <li>CONDITIONS</li>
          <li>LICENSES</li>
        </ul>
        <img className="mx-20 sm:block hidden" src={VerticalPartition} alt=''/>
        <div className=" flex flex-col items-center justify-between space-y-8 w-full ">
          
          <p className="text-center text-white font-bold">
            SOCIALIZE WITH TECHFLIX
          </p>
          <div className="flex justify-center space-x-8">
            <img src={facebook} alt=''/>
            <img src={twitter} alt=''/>
            <img src={linkedin} alt=''/>
            <img src={youtube} alt=''/>
            <img src={instagram} alt=''/>
            <img src={pinterest} alt=''/>
          </div>

          <button className="w-60% rounded-10 text-sm py-2.5 text-center bg-gradient-to-r from-[#8176AF] to-[#C0B7E8]">
            BUILD YOUR WORLD
          </button>
        </div>
      </div>
      <img className="w-90% sm:w-full my-20" src={Endline} alt=''/>
      <p className="text-white text-center font-bold text-sm">
        2023 © TECHFLIX LANDING PAGE - ALL RIGHTS RESERVED{" "}
      </p>
    </div>
  );
}

export default forwardRef(Footer);
