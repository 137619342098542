import React from "react";
import GirlVr from "../Assets/GirlVr.svg";
import RightArrow from "../Assets/RightArrow.svg";
import Location from "../Assets/Location.svg";
import StandingPartition from "../Assets/StandingPartition.svg";
import PhoneCall from "../Assets/PhoneCall.svg";
import Mail from "../Assets/Mail.svg";
import Home1 from "../Assets/Home1.svg";
import HomeBg from "../Assets/HomeBg.svg";

import Carousel from "react-elastic-carousel";

function Home() {
  const contact = [
    {
      img: Location,
      heading: "Pay Us a Visit",
      decs: "Union St, Seattle, WA 98101, United States",
    },
    {
      img: PhoneCall,
      heading: "Give Us a Call",
      decs: "(110) 1111-1010",
    },
    {
      img: Mail,
      heading: "Send Us a Message",
      decs: "Contact@HydraVTech.com",
    },
  ];
  return (
    <div className="bg-transparent">
      <div className="flex flex-wrap-reverse mt-24 justify-between bg-transparent">
        <div className="sm:w-2/5 w-full mx-4 bg-transparent">
          <div className="bg-transparent">
            <p className="text-white font-bold mt-9 text-center sm:text-left text-2xl sm:text-4xl leading-6 bg-transparent">
              <b
                className="bg-gradient-to-r from-[#C0B7E8] to-[#8176AF] bg-transparent"
                style={{
                  webkitBackgroundClip: "text",
                  webkitTextFillColor: "transparent",
                }}
              >
                Dive
              </b>{" "}
              Into The Depths Of{" "}
              <b
                className="bg-gradient-to-r from-[#C0B7E8] to-[#8176AF] "
                style={{
                  webkitBackgroundClip: "text",
                  webkitTextFillColor: "transparent",
                }}
              >
                Virtual Reality{" "}
                <b
                  className="bg-gradient-to-r from-[#C0B7E8] to-[#8176AF] hidden sm:block"
                  style={{
                    webkitBackgroundClip: "text",
                    webkitTextFillColor: "transparent",
                  }}
                >
                  & Augumented Reality
                </b>
              </b>
            </p>
          </div>
          <div className="text-white mt-6 hidden sm:block">
            Welcome to our world of augmented and virtual reality! Our
            cutting-edge AR VR technologies will take you on an immersive
            journey beyond your wildest dreams. Explore new horizons and unlock
            new possibilities with our innovative solutions.
          </div>
          <div className="flex justify-center sm:justify-start space-x-10 mt-6 ">
            <button className="rounded-10 text-sm px-10 py-2.5 text-center mr-3 sm:mr-0 bg-gradient-to-r from-[#8176AF] to-[#C0B7E8]">
              BUILD YOUR WORLD
            </button>

            <img className="hidden sm:block " src={RightArrow} alt="" />
          </div>
        </div>
        {/* <img className="sm:hidden block w-full " src={Home1}/> */}
        <div
          className="  flex items-center justify-center"
          // style={{
          //   backgroundImage: `url(${HomeBg})`,
          //   backgroundRepeat: "no-repeat",
          //   backgroundSize: "cover",
          //   backgroundPosition: "right center",
          // }}
        >
          <img
            className=" sm:w-100% w-full bg-transparent"
            
            src={GirlVr}
            alt=""
          />
        </div>
      </div>
      <div
        className="sm:flex hidden items-center overflow-scroll sm:overflow-visible divide-x w-full bg-gradient-to-r from-[#3A3456F2] to-[#211E2E]  py-4 px-4 mt-24"
        style={{
          borderRadius: "90px",
        }}
      >
        {contact.map((data, i) => (
          <div
            className="flex gap-7 bg-transparent mobile:w-full md:w-1/3"
            key={i}
          >
            <div className="flex items-center gap-3 px-6 bg-transparent">
              <img className="bg-transparent" src={data.img} alt="" />
              <div className="text-white bg-transparent">
                <p className="font-bold text-2xl bg-transparent sm:block hidden ">
                  {data.heading}
                </p>
                <p className="font-normal text-sm leading-4 mx-2 bg-transparent">
                  {data.decs}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div
        className="sm:hidden flex items-center overflow-scroll sm:overflow-visible divide-x w-full bg-gradient-to-r from-[#3A3456F2] to-[#211E2E]  py-4 px-4 mt-24"
        style={{
          borderRadius: "90px",
        }}
      >
        <Carousel>
          {contact.map((data, i) => (
            <div
              className="flex gap-7 bg-transparent mobile:w-full md:w-1/3"
              key={i}
            >
              <div className="flex items-center gap-3 px-6 bg-transparent">
                <img className="bg-transparent" src={data.img} alt="" />
                <div className="text-white bg-transparent">
                  <p className="font-bold text-2xl bg-transparent sm:block hidden">
                    {data.heading}
                  </p>
                  <p className="font-normal text-sm leading-4 mx-2 bg-transparent">
                    {data.decs}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default Home;
