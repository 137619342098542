import React, { forwardRef } from "react";
import TechVr from "../Assets/TechVr.svg";
import Unreal from "../Assets/Unreal.svg";
import Unity from "../Assets/Unity.svg";
import Oculus from "../Assets/Oculus.svg";
import Vive from "../Assets/Vive.svg";
import DownArrow from "../Assets/DownArrow.svg";
import Carousel from "react-elastic-carousel";
function Tech({}, ref) {
  const TechImages = [Unreal, Unity, Oculus, Vive];
  return (
    <div className="mt-8" ref={ref}>
      <div className="relative z-0  flex flex-col items-center justify-center">
        <img
          src={TechVr}
          className=" top-0 left-0"
          style={{ zIndex: "-1" }}
          alt=""
        />

        <div className="absolute bg-transparent">
          <p className=" sm:text-4xl text-xl text-center font-bold text-white bg-transparent">
            TECHNOLOGIES & HARDWARE
          </p>
          <p className=" sm:text-4xl text-2xl text-center font-light text-white bg-transparent">
            USED BY TECHFLIX.
          </p>
        </div>
        <img
          className="absolute bottom-[-8%] sm:block hidden text-center translate-y-1  bg-transparent rounded-full bg-gradient-to-br from-[#8176AF] to-[#C0B7E8] px-4 py-5 shadow-light-black shadow-custom1 "
          src={DownArrow}
          alt=""
        />
      </div>

      {/* Croouseal in Small view  */}
      <div className="sm:flex hidden flex-wrap justify-between items-center">
        {TechImages.map((data, i) => (
          <img src={data} key={i} alt="" />
        ))}
      </div>

      <div className="sm:hidden mt-4 flex flex-wrap justify-between items-center">
        <Carousel>
          {TechImages.map((data, i) => (
            <img src={data} key={i} alt="" />
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default forwardRef(Tech);
