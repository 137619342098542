import React, { useRef } from "react";
import "./App.css";
import About from "./components/About";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./components/Home";
import OptIn from "./components/OptIn";
import Process from "./components/Process";
import Services from "./components/Services";
import Tech from "./components/Tech";
import ScrollToTopOnRefresh from "./components/ScrollToTopOnRefresh";

function App() {
  const aboutRef = useRef(null);
  const servicesRef = useRef(null);
  const technologiesRef = useRef(null);
  const howtoRef = useRef(null);
  const getStartedRef = useRef(null);
  const contactUsRef = useRef(null);


  const scrollToAboutSection = () => {
    aboutRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToServiceSection = () => {
    servicesRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTechnologiesSection = () => {
    technologiesRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToHowtoSection = () => {
    howtoRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToGetStartedSection = () => {
    getStartedRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToContactUsSection = () => {
    contactUsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="px-8 pb-10  sm:px-16 ">
      <ScrollToTopOnRefresh />
      <Header
        scrollToAboutSection={scrollToAboutSection}
        scrollToServiceSection={scrollToServiceSection}
        scrollToTechnologiesSection={scrollToTechnologiesSection}
        scrollToHowtoSection={scrollToHowtoSection}
        scrollToGetStartedSection={scrollToGetStartedSection}
        scrollToContactUsSection={scrollToContactUsSection}
      />
      <Home />
      <About ref={aboutRef} />
      <Services ref={servicesRef} />
      <Tech ref={technologiesRef} />
      <Process ref={howtoRef} />
      <OptIn ref={getStartedRef} />
      <Footer ref={contactUsRef}/>
    </div>
  );
}

export default App;
