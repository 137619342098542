import React, { forwardRef } from "react";
import Underline from "../Assets/Underline.svg";

function OptIn({},ref) {
  return (
    <div className="flex flex-col items-center sm:mt-40 mt-20 mb-20 w-full p-10 rounded-[100px] text-white bg-gradient-to-br from-[#433D60] to-[#211E2E]" ref={ref}>
      <p className="my-4 text-4xl font-bold bg-transparent">
        JOIN TECHFLIX
      </p>
      <img className="my-4" src={Underline} alt=''/>
      <p className="my-4 bg-transparent">Let’s Build Your AR & VR Experience</p>
      <div className="flex w-90% gap-2 my-4 bg-transparent">
        <div className="w-50% bg-transparent">
          <input
            className="border-white border-2 rounded-10 py-4 px-5 w-full  bg-transparent text-sm"
            type="text"
            placeholder="First name"
          />
        </div>
        <div className="w-50% bg-transparent">
          <input
            className="border-white border-2 rounded-10 py-4 px-5 w-full bg-transparent text-sm "
            type="text"
            placeholder="Last name"
          />
        </div>
      </div>
      <div className="flex w-90% my-4 gap-2 bg-transparent">
        <div className="w-50% bg-transparent">
          <input
            className="border-white border-2 rounded-10 py-4 px-5 w-full  bg-transparent text-sm"
            type="email"
            placeholder="Email"
          />
        </div>
        <div className="w-50% bg-transparent">
          <input
            className="border-white border-2 rounded-10 py-4 px-5 w-full bg-transparent text-sm"
            type="number"
            placeholder="Phone Number"
          />
        </div>
      </div>
      <div className="w-90% my-4 bg-transparent">
        <input
          className="border-white border-2 rounded-10 py-4 px-5 w-full bg-transparent text-sm"
          type="text"
          placeholder="Subject"
        />
      </div>
      <div className="w-90% my-4 bg-transparent">
        <input
          className="border-white border-2 rounded-10 pt-4 pb-40 px-5 w-full bg-transparent text-sm"
          type="text"
          placeholder="Tell Us Something..."
        />
      </div>
      <button className="text-black text-xs rounded-10 bg-gradient-to-br from-[#8176AF] to-[#C0B7E8] py-3 px-9 mt-6">
      SEND TO TECHFLIX
      </button>
    </div>
  );
}

export default forwardRef(OptIn);
