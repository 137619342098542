import React, { forwardRef }  from "react";
import LongRightArrow from "../Assets/LongRightArrow.svg";
import HorizontalPartition from "../Assets/HorizontalPartition.svg";
import SimulationProfile from "../Assets/SimulationProfile.svg";
import EducationProfile from "../Assets/EducationProfile.svg";
import SelfcareProfile from "../Assets/SelfcareProfile.svg";
import OutdoorProfile from "../Assets/OutdoorProfile.svg";
import Carousel from "react-elastic-carousel";

function Services({},ref) {
  const cards = [
    {
      img: SimulationProfile,
      heading: "SIMULATION",
      desc: "Tech Flix offers high-quality AR VR simulations that enable users to experience real-world scenarios in a safe and controlled environment.",
    },
    {
      img: EducationProfile,
      heading: "EDUCATION",
      desc: "Tech Flix creates engaging and interactive AR VR content for education, providing immersive experiences that enhance learning and retention.",
    },
    {
      img: SelfcareProfile,
      heading: "SELF-CARE",
      desc: "Tech Flix provides personalized AR VR experiences for self-care, helping individuals to reduce stress and improve mental wellbeing.",
    },
    {
      img: OutdoorProfile,
      heading: "OUTDOOR",
      desc: "Tech Flix offers AR VR outdoor simulations for adventure and exploration, providing users with immersive experiences that bring the great outdoors to life.",
    },
  ];

  return (
    <div className=""ref={ref}>
      <div className="flex sm:justify-between justify-center text-white mt-28">
        <div className="">
          <p className="font-bold text-4xl">WHY BUILD</p>
          <div className="flex items-center gap-10 font-light text-4xl">
            <p>WITH TechFlix?</p>
            <img className="sm:block hidden" src={LongRightArrow} alt=''/>
          </div>
        </div>
        <p className="w-50% sm:block hidden">
          Tech Flix is an AR VR company founded in 2021 that specializes in
          software development, content creation, and simulations. With a team
          of expert developers and designers, we provide high-quality solutions
          to help businesses and individuals take advantage of the latest
          advancements in augmented and virtual reality. Our customized
          solutions and commitment to staying at the forefront of AR VR
          technology make us a trusted partner for anyone looking to unlock new
          possibilities in this exciting field.
        </p>
      </div>
      <div className="sm:flex hidden justify-between mt-14">
        {cards.map((data, i) => (
          <div
            className="flex flex-col items-center justify-between rounded-10 bg-gradient-to-br from-[#433D60] to-[#211E2E] w-full sm:w-20% py-8"
            key={i}
          >
            <img
              className="shadow-light-black w-60% shadow-custom1 rounded-50% "
              src={data.img}
            />
            <p className="text-white bg-transparent mt-6 font-bold ">
              {data.heading}
            </p>
            <img className="my-4" src={HorizontalPartition} alt=''/>
            <p className="w-90% text-white text-center text-xs bg-transparent">
              {data.desc}
            </p>
            <button className="text-xs rounded-10 bg-gradient-to-br from-[#8176AF] to-[#C0B7E8] py-3 px-9 mt-6">
              TRY IT NOW
            </button>
          </div>
        ))}
      </div>
      <div className="sm:hidden flex justify-between mt-14">
        <Carousel>
          {cards.map((data, i) => (
            <div
              className="flex flex-col items-center justify-between rounded-10 bg-gradient-to-br from-[#433D60] to-[#211E2E] w-full py-8"
              key={i}
            >
              <img
                className="shadow-light-black w-60% shadow-custom1 rounded-50% "
                src={data.img}
              />
              <p className="text-white bg-transparent mt-6 font-bold ">
                {data.heading}
              </p>
              <img className="my-4" src={HorizontalPartition} alt=''/>
              <p className="w-90% text-white text-center text-xs bg-transparent">
                {data.desc}
              </p>
              <button className="text-xs rounded-10 bg-gradient-to-br from-[#8176AF] to-[#C0B7E8] py-3 px-9 mt-6">
                TRY IT NOW
              </button>
            </div>
          ))}
        </Carousel>
      </div>
     
    </div>
  );
}

export default forwardRef(Services);
