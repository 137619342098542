import React, { forwardRef } from "react";
import LongRightArrow from "../Assets/LongRightArrow.svg";
import ProcessVector from "../Assets/ProcessVector.svg";
import RightBoldArrow from "../Assets/RightBoldArrow.svg";
import Carousel from "react-elastic-carousel";


function Process({a}, ref) {
  const processCard = [
    {
      num: "01",
      heading: "3D Conception & Design",
    },
    {
      num: "02",
      heading: "Interaction Design",
    },
    {
      num: "03",
      heading: "AR/VR World User Testing",
    },
    {
      num: "04",
      heading: "AR VR Deploy",
    },
  ];
  return (
    <div className="mt-10" ref={ref}>
      <div className="flex sm:justify-between items-center text-white bg-transparent">
        <div className="sm:w-auto w-full bg-transparent">
          <p className="sm:text-left text-center font-bold text-4xl bg-transparent">
            HOW WE BUILD
          </p>
          <div className="sm:w-auto w-full sm:flex  items-center gap-10 font-light text-4xl bg-transparent">
            <p className="sm:text-left text-center bg-transparent">WITH TechFlix?</p>
            <img className="sm:block hidden" src={LongRightArrow} alt=''/>
          </div>
        </div>
        <p className="w-50% sm:block hidden">
          To build with Techflix, you can get in touch with their team and
          discuss your specific requirements. Based on your needs, Techflix can
          provide a range of customized solutions, including AR VR software
          development, content creation, and simulations. Their team of expert
          developers, designers, and engineers will work closely with you to
          understand your unique needs and develop customized solutions that
          meet your specific requirements. Techflix is committed to staying at
          the forefront of AR VR technology, so you can be confident that you
          will receive the highest quality solutions that leverage the latest
          advancements in this exciting field.
        </p>
      </div>
      <div className="sm:relative mt-16">
        <img className="sm:block hidden" src={ProcessVector} alt=''/>
        <div className="absolute sm:flex hidden justify-evenly w-full">
          {processCard.map((data, i) => (
            <div className="flex flex-col items-center  space-y-8" key={i}>
              <div className="absolute font-bold text-5xl -translate-x-1/2 -translate-y-full text-[#343045] bg-transparent rounded-full bg-gradient-to-br from-[#8176AF] to-[#C0B7E8] px-9 py-10 shadow-light-black shadow-custom1 ">
                {data.num}
              </div>
              <div className="flex gap-5 ">
                <img className="sm:block hidden" src={RightBoldArrow} alt=''/>
                <p className="w-50% text-white font-bold">{data.heading}</p>
              </div>
            </div>
          ))}
        </div>

        <div className=" sm:hidden flex justify-evenly w-full">
          <Carousel>

          {processCard.map((data, i) => (
            <div className="flex flex-col items-center space-y-8 mt-5" key={i}>
              <div className=" font-bold text-5xl text-[#343045] bg-transparent rounded-full bg-gradient-to-br from-[#8176AF] to-[#C0B7E8] px-9 py-10 shadow-light-black shadow-custom1 ">
                {data.num}
              </div>
              <div className="flex gap-5 ">
                <p className="w-50% text-white font-bold">{data.heading}</p>
              </div>  
            </div>
          ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default forwardRef(Process);
