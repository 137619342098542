import React, { useRef } from "react";
import TechflixLogo from "../Assets/TechflixLogo.svg";
import HamburgerButton from "../Assets/HamburgerButton.svg";

function Header({
  scrollToAboutSection,
  scrollToServiceSection,
  scrollToTechnologiesSection,
  scrollToHowtoSection,
  scrollToGetStartedSection,
  scrollToContactUsSection,
}) {
  const sideBar = useRef();

  return (
    <div className="flex items-center justify-between bg-[#302C42] sticky py-5 top-0 z-50 w-full">
      <div className="flex items-center">
        <img className="w-20%" src={TechflixLogo} alt="" />
        <h1 className="text-white font-normal modile:text-3xl md:text-5xl">
          TechFlix
        </h1>
      </div>

      <nav className=" space-x-10 items-center justify-between sm:flex hidden">
        <ul className="flex space-x-10 font-semibold text-sm">
          <li className="text-white">
            <button onClick={scrollToAboutSection}>ABOUT</button>
          </li>
          <li className="text-white">
            <button onClick={scrollToServiceSection}>SERVICES</button>
          </li>
          <li className="text-white">
            <button onClick={scrollToTechnologiesSection}>TECHNOLOGIES</button>
          </li>
          <li className="text-white">
            <button onClick={scrollToHowtoSection}>HOW TO</button>
          </li>
        </ul>
      </nav>
      <div className="space-x-9 font-bold sm:flex hidden mx-2">
        <button
          type="button"
          className="text-white ring-2 ring-white text-sm px-5 py-2.5 text-center mr-3 md:mr-0 rounded-full"
          onClick={scrollToContactUsSection}
        >
          CONTACT US
        </button>
        <button
          type="button"
          className="rounded-full focus:ring-4 focus:outline-none font-semibold text-sm px-5 py-2.5 text-center mr-3 md:mr-0 bg-gradient-to-r from-[#8176AF] to-[#C0B7E8]"
          onClick={scrollToGetStartedSection}
        >
          Get started
        </button>
      </div>
      {/* side navbar  MOBILE */}
      <div className="sm:hidden block">
        <button
          className="text-white text-lg sm:hidden block font-bold "
          onClick={() => {
            sideBar.current.style.transform = "translateX(0px)";
          }}
        >
          <img src={HamburgerButton} alt="" />
        </button>

        {/* SIde bAR */}
        <div
          className="-transalate-x-52 h-screen z-10 fixed top-0 right-0 w-[200px] bg-gradient-to-br from-[#433D60] to-[#372f5d] transition-all"
          ref={sideBar}
        >
          <button
            className="text-white pl-2 text-3xl cursor-pointer"
            onClick={() => {
              sideBar.current.style.transform = "translateX(200px)";
            }}
          >
            x
          </button>

          <div className="flex flex-col items-center bg-transparent">
            <ul className="mt-5 space-y-5 font-semibold text-sm bg-transparent">
              <li className="text-white bg-transparent">
                <button onClick={scrollToAboutSection}>ABOUT</button>
              </li>
              <li className="text-white bg-transparent">
                <button onClick={scrollToServiceSection}>SERVICES</button>
              </li>
              <li className="text-white bg-transparent">
                <button onClick={scrollToTechnologiesSection}>
                  TECHNOLOGIES
                </button>
              </li>
              <li className="text-white bg-transparent">
                <button onClick={scrollToHowtoSection}>HOW TO</button>
              </li>
            </ul>

            <div className="text-center mt-5 space-y-5 font-semibold text-sm bg-transparent">
              <button
                type="button"
                className="text-white ring-2 ring-white text-sm px-5 py-2.5 text-center mr-3 md:mr-0 rounded-full"
                onClick={scrollToContactUsSection}
              >
                CONTACT US
              </button>
              <button
                type="button"
                className="rounded-full focus:ring-4 focus:outline-none font-semibold text-sm px-5 py-2.5 text-center mr-3 md:mr-0 bg-gradient-to-r from-[#8176AF] to-[#C0B7E8]"
                onClick={scrollToGetStartedSection}
              >
                Get started
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
